import Doctor from '../../assets/images/Lionel.webp';
function Presentation() {
  return (
    <section className="presentation">
      <div className="presentation__container">
        <img src={Doctor} alt="Lionel le Kinésiologue" />
        <div className="presentation__text__container">
          <h2>Lionel WILM - Kinésiologue</h2>
          <p>
            Je suis Lionel, âgé de 42 ans, et le fier papa d'une merveilleuse
            fille de 13 ans.
          </p>
          <p>
            Cela fait plus de 20 ans que je travaille en industrie, dans divers
            secteurs liés à la coordination et à la gestion, visant toujours à
            satisfaire pleinement mes clients.
          </p>
          <p>
            Néanmoins, ma quête de sens, de contribution et d'alignement m'a
            dirigé vers la kinésiologie.
          </p>
          <p>
            Cette approche, découverte pendant mon burn-out, m'a permis de
            surmonter les défis que la vie m'a réservés.
          </p>
          <p>
            Elle m'a offert la capacité de me relever inlassablement, de prendre
            du recul, de mieux me comprendre, d'accroître ma confiance en moi,
            de m'épanouir pour, chaque jour, devenir une meilleure version de
            moi-même.
          </p>
          <blockquote>
            "La question n'est pas de savoir si la vie a un sens, mais comment
            puis-je donner un sens à ma propre vie."
          </blockquote>
          <p style={{ textAlign: 'right' }}>DALAI-LAMA</p>
        </div>
      </div>
    </section>
  );
}

export default Presentation;
