import React from 'react';
import BannerTitle from '../components/bannerTitle/BannerTitle';
import BANNER_PIC from '../assets/pictures/pierres-zen-empilees-art-fond-sable-du-concept-equilibre_53876-110627.avif';
import mutuelles from '../assets/mutuelles.json';

export default function Forfaits() {
  return (
    <>
      <main className="forfaits consultations">
        <BannerTitle img={BANNER_PIC} title="Forfaits" />
        <section className="forfaits--section">
          <div>
            <h2>Tarif unique : </h2>
            <div>
              <ul>
                <li>
                  50€ la séance d'environ 1h (enfant, ado, adulte et sénior)
                </li>
              </ul>
            </div>
          </div>
          <div>
            <h2>Mutuelles acceptant la kinésiologie</h2>
            <div className="mutuelles--wrap">
              {mutuelles.map((mutuelle) => (
                <CardText
                  key={mutuelle.title}
                  title={mutuelle.title}
                  text={mutuelle.text}
                />
              ))}
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

function CardText({ step, title, text }) {
  return (
    <div className="card-container">
      <div className="card card-no-height">
        <div className="step--title">
          {step && <p>{step}</p>}
          <h3> {title}</h3>
        </div>
        <p>{text}</p>
      </div>
    </div>
  );
}
