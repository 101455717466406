import Card from '../card/Card';

function WhatIsIt(props) {
  const consultations = props.consultations;

  return (
    <section className="what-is-it">
      <div className="what-is-it__container">
        <div className="what-is-it__presentation">
          <h2>Qu'est-ce que la Kinésiologie et quand faire une séance?</h2>
          <p>
            La kinésiologie, étymologiquement « la science du mouvement » est
            une technique de rééquilibrage psycho-corporelle. Elle s’attache au
            bien-être global de la personne en tenant compte des aspects
            physique, émotionnel et mental.
          </p>
          <p>
            Elle a pour objectif de réduire le stress afin de redonner à chacun
            la possibilité de recontacter son potentiel, de retrouver son
            pouvoir personnel, la capacité de choisir et d’accéder à ses propres
            solutions. Le rééquilibrage énergétique de la kinésiologie
            s’effectue grâce à des méthodes empruntées à l’occident
            (chiropractie) et l’orient (médecine chinoise traditionnelle).
          </p>
          <p>
            La kinésiologie s’adresse à tout le monde et à tout âge. Voici des
            cas dans lesquels la kinésiologie peut vous aider :
          </p>
        </div>

        <div className="what-is-it__sessions">
          {consultations.map((consultation, index) => (
            <Card consultation={consultation} key={index} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default WhatIsIt;
