function Button(props) {
  const text = props.text;
  const style = props.buttonClass;
  const href = props.href;

  return (
    <button className={style} onClick={href}>
      {text}
    </button>
  );
}

export default Button;
