import Picture1 from '../../assets/images/exercice-2.webp';
import Picture2 from '../../assets/images/exercice-3.webp';
import Picture3 from '../../assets/images/exercice-5.webp';

function Session() {
  // eslint-disable-next-line jsx-a11y/heading-has-content
  return (
    <section className="session">
      <div className="sesssion_container">
        <div className="session__text__title">
          <h2>Déroulement d'une session</h2>
          <div className="session__text">
            <p>
              Une séance de kinésiologie est généralement structurée de la
              manière suivante :
            </p>
            <ul>
              <li>
                <strong>Entretien initial</strong> : Le kinésiologue commence
                par discuter avec la personne pour comprendre ses
                préoccupations, ses objectifs et son histoire médicale. Cette
                étape est cruciale pour personnaliser la séance.
              </li>
              <li>
                <strong>Tests musculaires</strong> : Le kinésiologue utilise des
                tests musculaires pour évaluer la force et l’équilibre
                musculaire de la personne. Cela peut impliquer de demander au
                testé de résister à une légère pression appliquée sur certains
                muscles.
              </li>
              <li>
                <strong>Définition de l’objectif de la séance</strong> : Avec
                une phrase d’action, positive, au temps présent et à la première
                personne qui symbolise ce qu’on souhaite obtenir comme résultat.
              </li>
              <li>
                <strong>Identification des déséquilibres</strong> : En utilisant
                les résultats des tests musculaires, le kinésiologue identifie
                les déséquilibres énergétiques ou physiologiques dans le corps
                du testé.
              </li>
              <li>
                <strong>Techniques de rééquilibrage</strong> : Le kinésiologue
                utilise différentes techniques, telles que la stimulation de
                points d’acupuncture, le travail sur les méridiens énergétiques,
                la massothérapie ou des exercices spécifiques, pour rééquilibrer
                les énergies du corps et traiter les problèmes identifiés.
              </li>
              <li>
                <strong>Feedback et ajustements</strong> : Pendant la séance, le
                kinésiologue peut donner des retours au testé sur les
                déséquilibres détectés et les progrès réalisés. Des ajustements
                sont apportés si nécessaire.
              </li>
              <li>
                <strong>Conseils</strong> : À la fin de la séance, le
                kinésiologue discute des objectifs à long terme avec la personne
                et donne des conseils sur les changements de mode de vie,
                l’exercice ou d’autres pratiques qui pourraient être utiles pour
                maintenir l’équilibre.
              </li>
              <li>
                <strong>Conclusion</strong> : La séance se termine par un
                échange sur les sensations de la personne et sur les prochaines
                étapes à suivre, y compris la possibilité de planifier une
                séance de suivi.
              </li>
            </ul>
          </div>
        </div>
        <div className="session__picture">
          <img src={Picture1} alt="patient souffrant" />
          <img
            className="image-secondaire"
            src={Picture2}
            alt="patient souffrant"
          />
          {/* <img
            className="image-secondaire"
            src={Picture3}
            alt="patient souffrant"
          /> */}
        </div>
      </div>
    </section>
  );
}

export default Session;
