import React from 'react';
import BannerTitle from '../components/bannerTitle/BannerTitle';
import BANNER_IMAGE from '../assets/pictures/pierres-zen-empilees-art-fond-sable-du-concept-equilibre_53876-110627.avif';

export default function ConsultationByType() {
  return (
    <main className="consultations">
      <BannerTitle img={BANNER_IMAGE} title={'Gestions des émotions'} />
      <section className="consultations--by--trouble">
        <div className="title-text">
          <h2>Effet de la kinésiologie sur les émotions</h2>
          <p>
            La gestion des émotions est un aspect crucial du bien-être mental et
            de l'équilibre émotionnel. Cela implique la capacité à reconnaître,
            comprendre et réguler ses propres émotions pour naviguer
            efficacement à travers les diverses situations de la vie
            quotidienne.
          </p>
          <p>
            Les émotions, qu'elles soient positives comme la joie et
            l'enthousiasme ou négatives comme la colère et la tristesse, font
            partie intégrante de l'expérience humaine. Une gestion saine des
            émotions implique la capacité à exprimer et à gérer ces sentiments
            de manière adaptative, sans qu'ils ne perturbent considérablement le
            fonctionnement quotidien.
          </p>
          <p>
            Cependant, la gestion des émotions peut être un défi pour de
            nombreuses personnes. Des facteurs tels que le stress, les
            traumatismes passés, les pressions sociales ou les problèmes
            personnels peuvent rendre difficile la gestion efficace des
            émotions.
          </p>
          <p>
            La kinésiologie offre une approche holistique pour aider à la
            gestion des émotions. En identifiant les déséquilibres énergétiques
            et en travaillant sur les blocages émotionnels, elle permet de
            libérer les tensions accumulées. Grâce à des techniques
            personnalisées telles que le rééquilibrage énergétique, la
            respiration consciente et la libération émotionnelle, la
            kinésiologie offre des outils concrets pour mieux comprendre et
            gérer les émotions. En favorisant une meilleure conscience de soi et
            en renforçant les compétences émotionnelles, elle contribue à une
            gestion plus saine des émotions, permettant ainsi d'améliorer la
            qualité de vie et le bien-être global.
          </p>
        </div>
      </section>
    </main>
  );
}
