import LOGO from '../../assets/icons/lotus.svg';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer>
      <div className="footer--container">
        <div className="ouvertures">
          <p>
            Du lundi au jeudi de 17h à 19h, vendredi de 16h à 19h et samedi de
            9h à 16h
          </p>
          <p>Possibilité de rendez-vous hors de ces créneaux sur demande</p>
        </div>
        <div className="footer--container__logo">
          <h4>Lionel Wilm - Kinésiologue</h4>
          <img src={LOGO} alt="logo" />
        </div>
        <p>Le chemin vers une vie épanouie</p>
        <div className="footer--container__contact">
          <Link
            to="#"
            onClick={(e) => {
              window.location.href = 'mailto:lio-kinesio@gmail.com';
              e.preventDefault();
            }}
          >
            Contacter Lionel
          </Link>
          <Link to={'https://www.linkedin.com/in/luc-schenherr-810205140/'}>
            <p style={{ margin: 0 }}>Site web créé par Schenherr Luc</p>
          </Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
