import React from 'react';
import BannerTitle from '../components/bannerTitle/BannerTitle';
import BANNER_IMAGE from '../assets/pictures/pierres-zen-empilees-art-fond-sable-du-concept-equilibre_53876-110627.avif';

export default function ConsultationByType() {
  return (
    <main className="consultations">
      <BannerTitle img={BANNER_IMAGE} title={'Trouble du sommeil'} />
      <section className="consultations--by--trouble">
        <div className="title-text">
          <h2>Troubles du sommeil</h2>
          <p>
            Les troubles du sommeil, une problématique courante de la société
            moderne, englobent une gamme de conditions perturbant la qualité et
            la durée du repos nocturne. Insomnie, apnée du sommeil,
            somnambulisme, ou encore syndrome des jambes sans repos font partie
            des troubles fréquemment rencontrés.
          </p>
          <p>
            Ces problèmes peuvent se manifester par des difficultés à
            s'endormir, des réveils fréquents pendant la nuit, un sommeil non
            réparateur ou des épisodes de somnolence excessive durant la
            journée. Les troubles du sommeil peuvent également provoquer de la
            fatigue, une baisse de la concentration et une altération des
            performances cognitives.
          </p>
          <p>
            L'impact des troubles du sommeil va bien au-delà de la simple
            fatigue. Ils peuvent influencer la santé mentale en exacerbant le
            stress, l'anxiété et la dépression. De plus, ils sont souvent
            associés à un risque accru de développer des problèmes de santé tels
            que les maladies cardiovasculaires, le diabète et l'obésité.
          </p>
          <p>
            Cependant, la kinésiologie offre une approche holistique pour
            aborder les troubles du sommeil. En identifiant les déséquilibres
            énergétiques et les sources de stress dans le corps, elle vise à
            restaurer l'harmonie mentale et physique. Les techniques de
            relaxation, de respiration et de rééquilibrage énergétique adaptées
            à chaque individu peuvent aider à améliorer la qualité du sommeil.
            En travaillant sur les causes sous-jacentes, la kinésiologie offre
            des outils pour favoriser un sommeil plus profond, plus réparateur
            et une meilleure qualité de vie globale.
          </p>
        </div>
      </section>
    </main>
  );
}
