import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Lotus from '../../assets/icons/lotus.svg';
import List from '../../assets/icons/list-ul-solid.svg';
import MobileNav from './mobile-nav/MobileNav';
import Chevron from '../../assets/icons/chevron.svg';
import Button from '../button/Button';

function Header() {
  // État et fonctions de navigation
  const navigate = useNavigate();

  const goToHome = () => navigate('/');
  const goToResilib = () =>
    window.open('https://lio-kinesio.reservio.com/booking', '_blank').focus();

  // État et fonctions pour la navigation mobile
  const [isRotate, setIsRotate] = useState(false);
  const [displayMobileNav, setDisplayMobileNav] = useState(false);

  useEffect(() => {
    document.body.style.overflow = displayMobileNav ? 'hidden' : 'auto';
  }, [displayMobileNav]);

  // Fonction pour aller à la section de contact
  const handleGoToContact = () => {
    if (window.location.pathname !== '/') {
      navigate('/', { state: { scrollToContact: true } });
    } else {
      const contactUsElements = document.getElementsByClassName('contact-us');
      if (contactUsElements.length > 0) {
        contactUsElements[0].scrollIntoView({ behavior: 'smooth' });
      }
    }

    // const contactUsElements = document.getElementsByClassName('contact-us');
    // if (contactUsElements.length > 0) {
    //   contactUsElements[0].scrollIntoView({ behavior: 'smooth' });
    // }
  };

  const updateDisplay = (newState) => {
    setDisplayMobileNav(newState);
  };

  return (
    <>
      <header>
        <div className="header__container">
          <div className="lio-logo" onClick={goToHome}>
            <img className="lotus" src={Lotus} alt="Logo de l'entreprise" />
            <p id="logo">Lionel Wilm - Kinesiologie</p>
          </div>

          <MobileNav
            displayNav={displayMobileNav}
            update={updateDisplay}
            isRotate={isRotate}
            setIsRotate={setIsRotate}
          />

          <div className="header__container__nav">
            <div
              className="list-img"
              onClick={(e) => {
                e.stopPropagation();
                setDisplayMobileNav(true);
              }}
            >
              <img className="list-icon" src={List} alt="liste" />
            </div>
            <nav>
              <ul>
                <li>
                  <Link to="/">Accueil</Link>
                </li>
                <li
                  onMouseEnter={() => setIsRotate(true)}
                  onMouseLeave={() => setIsRotate(false)}
                  onClick={() => setIsRotate(false)}
                >
                  <ul onMouseLeave={() => setIsRotate(false)}>
                    <li>
                      <div className="link-chevron">
                        <Link
                          to="/consultations"
                          onMouseEnter={() => setIsRotate(true)}
                        >
                          Consultations
                        </Link>
                        <img
                          src={Chevron}
                          alt="chevron"
                          style={
                            isRotate
                              ? { transform: 'rotate(0deg)' }
                              : { transform: 'rotate(180deg)' }
                          }
                        />
                      </div>
                    </li>

                    <div
                      className="liste"
                      style={
                        isRotate
                          ? { maxHeight: '9999px' }
                          : { maxHeight: '0', padding: '0' }
                      }
                    >
                      <Link to="/consultations/stress">
                        <li>Stresse</li>
                      </Link>
                      <Link to="/consultations/anxiété">
                        <li>Anxieté</li>
                      </Link>
                      <Link to="/consultations/trouble-du-sommeil">
                        <li>Trouble du sommeil</li>
                      </Link>
                      <Link to="/consultations/troubles-digestifs">
                        <li>Troubles digestifs</li>
                      </Link>
                      <Link to="/consultations/gestion-des-émotions">
                        <li>Gestion des émotions</li>
                      </Link>
                      <Link to="/consultations/confiance-en-soi">
                        <li>Confiance en soi</li>
                      </Link>
                    </div>
                  </ul>
                </li>

                <li>
                  <Link to="/forfaits">Tarifs</Link>
                </li>
              </ul>
            </nav>
            <div className="contact-buttons">
              <Button
                text="Prendre un rendez-vous"
                buttonClass="appointment-button"
                href={goToResilib}
              />
              <Link to={'/'}>
                <Button
                  text="Contacter Lionel"
                  buttonClass="appointment-contact"
                  href={handleGoToContact}
                />
              </Link>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
