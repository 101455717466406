import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Chevron from '../../../assets/icons/chevron.svg';

function MobileNav({ displayNav, update, isRotate, setIsRotate }) {
  const containerRef = useRef(null);

  useEffect(() => {
    function handleOutsideClick(e) {
      if (
        displayNav &&
        containerRef.current &&
        !containerRef.current.contains(e.target)
      ) {
        update(!displayNav);
      }
    }

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [displayNav, update]);

  return (
    <nav
      className="mobile-nav"
      style={{
        display: displayNav ? 'block' : 'none',
        zIndex: displayNav ? '2000' : '-2000',
      }}
    >
      <div className="mobile-nav__container" ref={containerRef}>
        <div className="close-button">
          <div
            className="container-close-button"
            onClick={() => update(!displayNav)}
          >
            <div className="icone-close" onClick={() => setIsRotate(false)}>
              X
            </div>
          </div>
        </div>
        <ul>
          <li onClick={() => update((prev) => !prev)}>
            <Link to="/">Accueil</Link>
          </li>
          <li>
            <ul
              onMouseEnter={() => setIsRotate(true)}
              onMouseLeave={() => setIsRotate(false)}
            >
              <div
                className="link-chevron"
                onClick={() => setIsRotate((prev) => !prev)}
              >
                <p>Consultations</p>
                <img
                  src={Chevron}
                  alt="chevron"
                  style={
                    isRotate
                      ? { transform: 'rotate(0deg)' }
                      : { transform: 'rotate(180deg)' }
                  }
                />
              </div>
              <div
                className="liste-nav"
                style={
                  isRotate
                    ? { maxHeight: '400px' }
                    : { maxHeight: '0', padding: '0' }
                }
              >
                <Link
                  to="/consultations/stress"
                  onClick={() => update((prev) => !prev)}
                >
                  <li>Stresse</li>
                </Link>
                <Link
                  to="/consultations/anxiété"
                  onClick={() => update((prev) => !prev)}
                >
                  <li>Anxieté</li>
                </Link>
                <Link
                  to="/consultations/trouble-du-sommeil"
                  onClick={() => update((prev) => !prev)}
                >
                  <li>Trouble du sommeil</li>
                </Link>
                <Link
                  to="/consultations/troubles-digestifs"
                  onClick={() => update((prev) => !prev)}
                >
                  <li>Troubles digestifs</li>
                </Link>
                <Link
                  to="/consultations/gestion-des-émotions"
                  onClick={() => update((prev) => !prev)}
                >
                  <li>Gestion des émotions</li>
                </Link>
              </div>
            </ul>
          </li>
          <li onClick={() => update((prev) => !prev)}>
            <Link to="/forfaits">Tarifs</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default MobileNav;
