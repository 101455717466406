import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from '../../pages/Home';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import Cabinet from '../../pages/Cabinet';
import Consultations from '../../pages/Consultations';
import News from '../../pages/News';

import Forfaits from '../../pages/Forfaits';
import Stress from '../../pages/Stress.js';

import Sommeil from '../../pages/Sommeil.js';
import Digestion from '../../pages/Digestion.js';
import Émotions from '../../pages/Émotions.js';
import Confiance from '../../pages/Confiance.js';
import { ConsultationAnxiety } from '../../pages/Anxiety.js';

export default function Routers() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="le-cabinet" element={<Cabinet />} />
        <Route path="news" element={<News />} />
        <Route path="forfaits" element={<Forfaits />} />
        <Route path="consultations" element={<Consultations />} />
        <Route path="consultations/stress" element={<Stress />} />
        <Route path="consultations/anxiété" element={<ConsultationAnxiety />} />
        <Route path="consultations/trouble-du-sommeil" element={<Sommeil />} />
        <Route
          path="consultations/troubles-digestifs"
          element={<Digestion />}
        />
        <Route
          path="consultations/gestion-des-émotions"
          element={<Émotions />}
        />
        <Route path="consultations/confiance-en-soi" element={<Confiance />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}
