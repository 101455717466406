import Banner from '../components/banner/Banner';
import Presentation from '../components/presentation/Presentation';
import WhatIsIt from '../components/what-is-it/WhatIsIt';
import Session from '../components/session/Session';
import ContactUs from '../components/contact-us/ContactUs';
import Consultations from '../assets/mock-sessions.json';

function Home() {
  return (
    <>
      <main>
        <Banner />
        <Presentation />
        <WhatIsIt consultations={Consultations} />
        <Session />
        <ContactUs />
      </main>
    </>
  );
}

export default Home;
