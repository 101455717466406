import { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

function ContactUs() {
  const [textButton, setTextButton] = useState('Envoyer');
  const [userInfos, setUserInfos] = useState({
    firstName: 'Luc',
    lastName: '',
    phoneNumber: '',
    email: '',
  });
  const [buttonIsDisable, setButtonIsDisable] = useState(false);

  const form = useRef();
  function handleSubmit(e) {
    // // Prevent the browser from reloading the page
    e.preventDefault();

    const formData = new FormData(e.target);

    const lastName = formData.get('user_lastname');
    const firstName = formData.get('user_name');
    const phone = formData.get('user_phone');
    const email = formData.get('user_email');
    const message = formData.get('message');
    setUserInfos({
      firstName: firstName,
      lastName: lastName,
      phone: phone,
      user_email: email,
      message: message,
    });

    console.log(form.current);

    if (email && message) {
      setTextButton("Encours d'envoi");
      emailjs
        .sendForm(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID,
          form.current,
          process.env.REACT_APP_PUBLIC_KEY
        )
        .then(
          () => {
            setUserInfos({
              firstName: '',
              lastName: '',
              phoneNumber: '',
              user_email: '',
            });
            setTextButton('Message envoyé!');
            setTimeout(() => {
              setTextButton('Envoyer');
              setButtonIsDisable((prev) => !prev);
            }, 5000);
          },
          () => {
            alert("Something went wrong, email hasn't been send");
            setTextButton('Erreur');
            setTimeout(() => {
              setTextButton('Envoyer');
              setButtonIsDisable((prev) => !prev);
            }, 3500);
          }
        );
    } else {
      return alert('Fill in all the fields to continue');
    }
  }

  return (
    <section className="contact-us">
      <div className="contact-us__width">
        <h2>Contacter Lionel</h2>
        <h3>
          Prenez rendez-vous par message ou obtenez davantage d'informations sur{' '}
          <strong>la kinésiologie</strong>. Lionel vous répondra sous 24h.
        </h3>
        <div className="contact-us__container">
          <div className="contact-us__container__form">
            <form ref={form} method="post" onSubmit={handleSubmit}>
              <fieldset
                style={{ border: 'none' }}
                className="contact-us__container__infos_area"
              >
                <div className="contact-us__container__infos">
                  <input
                    type="text"
                    id="user_lastname"
                    name="user_lastname"
                    placeholder="Votre nom"
                  />
                  <input
                    type="text"
                    id="user_name"
                    name="user_name"
                    placeholder="Votre prénom"
                  />
                  <input
                    type="tel"
                    id="user_phone"
                    name="user_phone"
                    placeholder="Votre numéro pour être rappelé par Lionel"
                  />
                  <input
                    type="email"
                    id="user_email"
                    name="user_email"
                    placeholder="Votre email"
                  />
                </div>
                <textarea
                  id="message"
                  name="message"
                  placeholder="Votre message..."
                />
              </fieldset>
              <div className="send--button">
                <button disabled={buttonIsDisable} type="submit">
                  {textButton}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
