function BannerTitle({ img, title }) {
  return (
    <div
      className="banner--title"
      style={{
        background: `url(${img}) no-repeat center`,
        backgroundSize: `cover`,
      }}
    >
      <h1>{title}</h1>
    </div>
  );
}

export default BannerTitle;
