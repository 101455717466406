import Chevron from '../../assets/icons/chevron.svg';
import { useNavigate } from 'react-router-dom';

function Card(props) {
  const consultation = props.consultation;
  const index = props.index;
  const navigate = useNavigate();

  const handleMoreInfoRedirection = () => {
    navigate(consultation.link);
  };

  return (
    <div className="card-container">
      <div className="card" key={index}>
        <div className="title--icone">
          <i className={consultation.image}></i>
          <h3> {consultation.title}</h3>
        </div>
        <p>{consultation.text}</p>
        <button onClick={handleMoreInfoRedirection}>
          En savoir plus <img src={Chevron} alt="Chevron" />
        </button>
      </div>
    </div>
  );
}

export default Card;
