import image from '../../assets/images/main.webp';

function Banner() {
  return (
    <section className="banner">
      <img src={image} alt="main sur main - utilisé pour illustré la banière" />
      <div className="banner__text">
        <p>Lionel Wilm</p>
        <div>
          <span>Votre Kinésiologue</span>
          <span>Secteur Molsheim / Mutzig</span>
        </div>
      </div>
    </section>
  );
}

export default Banner;
