import BannerTitle from '../components/bannerTitle/BannerTitle';
import { useState } from 'react';

// images
import BANNER_IMAGE from '../assets/pictures/pierres-zen-empilees-art-fond-sable-du-concept-equilibre_53876-110627.avif';

import TEST from '../assets/pictures/14-tests-base-kinesiologie.jpg';

import CardText from '../components/card/CardText/CardText';
import ContactUs from '../components/contact-us/ContactUs';

function Consultations() {
  const [zoomed, setZoomed] = useState(false);

  const toggleZoom = () => {
    setZoomed(!zoomed);
  };

  return (
    <main className="consultations">
      <BannerTitle img={BANNER_IMAGE} title="Consultations" />
      <section className="consultations__objectifs--raisons">
        <div className="consultations__objectifs">
          <div className="consultations__objectifs--container">
            <div className="consultations__objectifs--container__text--image">
              <div className="text">
                <div className="title">
                  <h2>Déroulement d'une séance</h2>
                  <div className="title--design">
                    <div className="circle-1"></div>
                    <div className="circle-2"></div>
                    <div className="circle-3"></div>
                  </div>
                </div>
                <div className="text--container">
                  <CardText
                    step="Étape 1"
                    title="Entretien initial"
                    text="Le kinésiologue commence par discuter avec la personne pour comprendre ses préoccupations, ses objectifs et son histoire médicale. Cette étape est cruciale pour personnaliser la séance."
                  />

                  <CardText
                    step="Étape 2"
                    title="Tests musculaires"
                    text="Le kinésiologue utilise des tests musculaires pour évaluer la force et l’équilibre musculaire de la personne. Cela peut impliquer de demander au testé de résister à une légère pression appliquée sur certains muscles."
                  />

                  <CardText
                    step="Étape 3"
                    title="Définition de l’objectif de la séance"
                    text="Avec une phrase d’action, positive, au temps présent et à la première personne qui symbolise ce qu’on souhaite obtenir comme résultat."
                  />

                  <CardText
                    step="Étape 4"
                    title="Identification des déséquilibres"
                    text="En utilisant les résultats des tests musculaires, le kinésiologue identifie les déséquilibres énergétiques ou physiologiques dans le corps du testé."
                  />

                  <CardText
                    step="Étape 5"
                    title="Techniques de rééquilibrage"
                    text="Le kinésiologue utilise différentes techniques, telles que la stimulation de points d’acupuncture, le travail sur les méridiens énergétiques, la massothérapie ou des exercices spécifiques, pour rééquilibrer les énergies du corps et traiter les problèmes identifiés."
                  />

                  <CardText
                    step="Étape 6"
                    title="Feedback et ajustements"
                    text="Pendant la séance, le kinésiologue peut donner des retours au testé sur les déséquilibres détectés et les progrès réalisés. Des ajustements sont apportés si nécessaire."
                  />

                  <CardText
                    step="Étape 7"
                    title="Conseils"
                    text="À la fin de la séance, le kinésiologue discute des objectifs à long terme avec la personne et donne des conseils sur les changements de mode de vie, l’exercice ou d’autres pratiques qui pourraient être utiles pour maintenir l’équilibre."
                  />

                  <CardText
                    step="Étape 8"
                    title="Conclusion"
                    text="La séance se termine par un échange sur les sensations de la personne et sur les prochaines étapes à suivre, y compris la possibilité de planifier une séance de suivi."
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="consultations__objectifs--container">
            <div className="consultations__objectifs--container__text-image">
              <div className="text">
                <h2>Objectifs de la kinésiologie</h2>
                <p>
                  La kinésiologie, étymologiquement « la science du mouvement »
                  est une technique de rééquilibrage psycho-corporelle. Elle
                  s’attache au bien-être global de la personne en tenant compte
                  des aspects physique, émotionnel et mental.
                </p>
                <p>
                  La kinésiologie est une « une pratique professionnelle
                  destinée à favoriser un état d’équilibre et de bien-être » de
                  la personne dans son ensemble.
                </p>
                <p>
                  Elle a pour objectif de réduire le stress afin de redonner à
                  chacun la possibilité de recontacter son potentiel, de
                  retrouver son pouvoir personnel, la capacité de choisir et
                  d’accéder à ses propres solutions.
                </p>
                <p>
                  Le rééquilibrage énergétique de la kinésiologie s’effectue
                  grâce à des méthodes empruntées à l’occident (chiropractie) et
                  l’orient (médecine chinoise traditionnelle).
                </p>
              </div>
              <div className="image" onClick={toggleZoom}>
                <img src={TEST} alt="14 tests de la kinésiologie" />
              </div>
              {zoomed && (
                <div className="zoomed--container" onClick={toggleZoom}></div>
              )}
            </div>
          </div>
        </div>
      </section>
      <ContactUs />
    </main>
  );
}

export default Consultations;
