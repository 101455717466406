import React from 'react';
import BannerTitle from '../components/bannerTitle/BannerTitle';
import BANNER_IMAGE from '../assets/pictures/pierres-zen-empilees-art-fond-sable-du-concept-equilibre_53876-110627.avif';

export default function ConsultationByType() {
  return (
    <main className="consultations">
      <BannerTitle img={BANNER_IMAGE} title={'Stress'} />
      <section className="consultations--by--trouble">
        <div className="title-text">
          <h2>Le stress et ses impacts</h2>

          <p>
            Le stress, une réaction naturelle du corps à des situations perçues
            comme menaçantes ou difficiles, peut malheureusement devenir nocif
            lorsqu'il est chronique. Les effets néfastes du stress sur la santé
            sont multiples : il peut compromettre le système immunitaire,
            augmenter le risque de maladies cardiovasculaires, déclencher des
            maux de tête fréquents, provoquer des troubles du sommeil et même
            contribuer au développement de l'anxiété et de la dépression.
          </p>

          <p>
            À long terme, le stress chronique peut altérer la capacité de
            concentration, réduire la productivité et affaiblir les relations
            interpersonnelles. Les niveaux élevés de cortisol, l'hormone du
            stress, peuvent perturber l'équilibre hormonal et entraîner une
            variété de problèmes de santé physique et mentale.
          </p>
          <p>
            Dans ce contexte, il devient impératif de trouver des méthodes
            efficaces pour gérer et réduire le stress, et c'est là que la
            kinésiologie entre en jeu. En identifiant les tensions et les
            blocages dans le corps, cette pratique permet de libérer les
            émotions négatives accumulées, rétablissant ainsi l'équilibre
            énergétique et favorisant la relaxation. Grâce à son approche
            holistique, la kinésiologie offre des outils concrets pour contrer
            les effets néfastes du stress et retrouver un état de bien-être
            global.
          </p>
        </div>
      </section>
    </main>
  );
}
