import React from 'react';
import BannerTitle from '../components/bannerTitle/BannerTitle';
import BANNER_IMAGE from '../assets/pictures/pierres-zen-empilees-art-fond-sable-du-concept-equilibre_53876-110627.avif';

export default function ConsultationByType() {
  return (
    <main className="consultations">
      <BannerTitle img={BANNER_IMAGE} title={'Confiance en soi'} />
      <section className="consultations--by--trouble">
        <div className="title-text">
          <h2>Confiance en soi</h2>
          <p>
            La confiance en soi représente un élément essentiel pour mener une
            vie épanouie et accomplie. Elle se réfère à la croyance en ses
            propres capacités, à une estime de soi positive et à la conviction
            intérieure de pouvoir faire face aux défis de la vie quotidienne.
          </p>
          <p>
            Une confiance en soi solide permet à une personne de prendre des
            décisions de manière autonome, de s'affirmer dans ses relations, et
            de persévérer face aux obstacles. Elle favorise également une
            attitude positive, renforce la résilience et améliore la capacité à
            gérer le stress.
          </p>
          <p>
            Cependant, pour beaucoup, la confiance en soi peut être fluctuante
            et influencée par divers facteurs tels que les expériences passées,
            les critiques extérieures, ou encore les comparaisons avec les
            autres.
          </p>
          <p>
            La kinésiologie propose une approche holistique pour renforcer la
            confiance en soi. En identifiant les blocages énergétiques et en
            travaillant sur les croyances limitantes, elle aide à libérer les
            tensions qui entravent la confiance personnelle. Grâce à des
            techniques personnalisées telles que la visualisation, la
            réorientation des pensées négatives et le renforcement des
            ressources intérieures, la kinésiologie offre des outils concrets
            pour développer une confiance en soi durable et authentique. En
            favorisant l'épanouissement personnel et en encourageant
            l'acceptation de soi, elle contribue à renforcer la confiance
            intérieure, conduisant ainsi à une vie plus satisfaisante et
            équilibrée.
          </p>
        </div>
      </section>
    </main>
  );
}
