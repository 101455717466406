import React from 'react';
import BannerTitle from '../components/bannerTitle/BannerTitle';
import BANNER_IMAGE from '../assets/pictures/pierres-zen-empilees-art-fond-sable-du-concept-equilibre_53876-110627.avif';

export default function ConsultationByType() {
  return (
    <main className="consultations">
      <BannerTitle img={BANNER_IMAGE} title={'Problèmes digestifs'} />
      <section className="consultations--by--trouble">
        <div className="title-text">
          <h2>Troubles digestifs</h2>
          <p>
            Les troubles digestifs, englobant une variété de problèmes allant
            des ballonnements et des douleurs abdominales aux reflux acides et à
            la constipation, peuvent sérieusement affecter la qualité de vie
            d'une personne. Ces affections peuvent être causées par divers
            facteurs tels que le stress, une alimentation déséquilibrée, des
            infections ou des conditions médicales spécifiques.
          </p>
          <p>
            Les symptômes des troubles digestifs incluent souvent des douleurs
            abdominales, des ballonnements, des diarrhées ou constipations
            récurrentes, des nausées et des reflux acides. Ces problèmes peuvent
            non seulement être inconfortables, mais aussi impacter la capacité à
            vaquer à ses occupations quotidiennes.
          </p>
          <p>
            En plus des symptômes physiques, les troubles digestifs peuvent
            avoir un impact sur la santé mentale. Ils peuvent provoquer de
            l'anxiété, du stress et de la frustration chez ceux qui en
            souffrent, entraînant parfois une altération de la qualité de vie.
          </p>
          <p>
            La kinésiologie offre une approche holistique pour aborder les
            troubles digestifs en identifiant les déséquilibres énergétiques et
            en travaillant sur les causes sous-jacentes. En utilisant des
            techniques personnalisées, telles que le rééquilibrage énergétique
            et les conseils en nutrition, elle vise à restaurer l'harmonie du
            système digestif. En favorisant la détente et en réduisant le
            stress, la kinésiologie peut aider à atténuer les symptômes et à
            améliorer la santé digestive, contribuant ainsi au bien-être
            général.
          </p>
        </div>
      </section>
    </main>
  );
}
