import React from 'react';
import BannerTitle from '../components/bannerTitle/BannerTitle';
import BANNER_IMAGE from '../assets/pictures/pierres-zen-empilees-art-fond-sable-du-concept-equilibre_53876-110627.avif';

export function ConsultationAnxiety() {
  return (
    <main className="consultations">
      <BannerTitle img={BANNER_IMAGE} title={'Anxiété'} />
      <section className="consultations--by--trouble">
        <div className="title-text">
          <h2>L'anxiété et ses impacts</h2>
          <p>
            L'angoisse, une manifestation intense de l'anxiété, est un état
            émotionnel affaiblissant pouvant avoir des répercussions profondes
            sur la santé mentale et physique des individus. Elle se caractérise
            souvent par une sensation d'appréhension intense, de nervosité et de
            peur excessive sans raison apparente.
          </p>
          <p>
            Les symptômes de l'angoisse peuvent être variés, allant des
            palpitations cardiaques et de la transpiration excessive à des
            difficultés respiratoires, des crises de panique et même des
            troubles digestifs. Les personnes souffrant d'angoisse peuvent
            également ressentir une sensation d'irréalité, des pensées négatives
            persistantes et une peur intense de perdre le contrôle.
          </p>
          <p>
            Cette condition peut altérer la qualité de vie, interférer avec les
            activités quotidiennes et entraîner un isolement social. L'impact de
            l'angoisse peut être profond, affectant la concentration, la
            productivité au travail et les relations personnelles.
          </p>
          <p>
            Cependant, la kinésiologie propose une approche holistique pour
            traiter l'angoisse. En identifiant les déséquilibres énergétiques et
            les blocages émotionnels, elle permet de libérer les tensions et de
            rétablir l'harmonie entre le corps et l'esprit. Les techniques de
            relaxation, la respiration contrôlée et d'autres méthodes
            personnalisées de la kinésiologie offrent des outils pratiques pour
            apaiser les sensations d'angoisse, favorisant ainsi un soulagement
            durable et une amélioration du bien-être mental et émotionnel.
          </p>
        </div>
      </section>
    </main>
  );
}
