function CardText({ step, title, text }) {
  return (
    <div className="card-container">
      <div className="card">
        <div className="step--title">
          {step && <p>{step}</p>}
          <h3> {title}</h3>
        </div>
        <p>{text}</p>
      </div>
    </div>
  );
}

export default CardText;
